//import { ReactDOM } from 'react';
import { useState, useEffect } from 'react';
import { Button, Card, Checkbox, Input, Textarea, PageHeader, Spinner } from '@salesforce/design-system-react';
import FooterComponent from '../../components/Footer';

var SDK = require('blocksdk');
var sdk = new SDK();

const TDG_IN_MainBlock_LR = () => {

    const blockTitle = 'TDG IN Main Block LR';
    const [loading, setLoading] = useState('hidden');
    const [stateData, setStateData] = useState({
        name: '',
        show: '',
        date: '',
        //
        copy: '',
        // 
        presale: '',
        code: '',
        onsale: '',
        //
        ctaLearn: '',
        ctaLearnLink: '',
        ctaEvent: '',
        ctaEventLink: '',
        ctaTickets: '',
        ctaTicketsLink: '',
        //
        image480: '',
        image600: '',
        //
        toggles: {
            name: true,
            show: true,
            date: true,
            copy: true,
            presale: true,
            code: true,
            onsale: true,
            ctaLearn: false,
            ctaEvent: false,
            ctaTickets: true,
        }
    });

    //#region main functions
    const content = () => {
        return (`%%[
            SET @name = "${stateData.name}"
            SET @show = "${stateData.show}"
            SET @date = "${stateData.date}"
            
            SET @presale = "${stateData.presale}"
            SET @code = "${stateData.code}"
            SET @onsale = "${stateData.onsale}"
            
            SET @copy = "${stateData.copy}"
            
            // SET @ctaLearn = "${stateData.ctaLearn}"
            // SET @ctaLearnLink = "${stateData.ctaLearnLink}"
            // SET @ctaEvent = "${stateData.ctaEvent}"
            // SET @ctaEventLink = "${stateData.ctaEventLink}"
            // SET @ctaTickets = "${stateData.ctaTickets}"
            // SET @ctaTicketsLink = "${stateData.ctaTicketsLink}"

            SET @image480 = "${stateData.image480}"
            SET @image600 = "${stateData.image600}"

            SET @displayName = "${stateData.toggles.name}"
            SET @displayShow = "${stateData.toggles.show}"
            SET @displayDate = "${stateData.toggles.date}"
            SET @displayCopy = "${stateData.toggles.copy}"
            SET @displayPresale = "${stateData.toggles.presale}"
            SET @displayCode = "${stateData.toggles.code}"
            SET @displayOnSale = "${stateData.toggles.onsale}"
            SET @displayCTALearn = "${stateData.toggles.ctaLearn}"
            SET @displayCTAEvent = "${stateData.toggles.ctaEvent}"
            SET @displayCTATickets = "${stateData.toggles.ctaTickets}"

            VAR @CTA, @CTALink
            IF @displayCTALearn == "true" THEN
                SET @CTA = @ctaLearn
                SET @CTALink = @ctaLearnLink
            ELSEIF @displayCTAEvent == "true" THEN
                SET @CTA = @ctaEvent
                SET @CTALink = @ctaEventLink
            ELSEIF @displayCTATickets == "true" THEN
                SET @CTA = @ctaTickets
                SET @CTALink = @ctaTicketsLink
            ENDIF

            IF (@displayName == "true") THEN
                SET @displayName = "block"
                SET @displayNameMSO = "all"
            ELSE
                SET @displayName = "none"
                SET @displayNameMSO = "none"
            ENDIF
            IF (@displayShow == "true") THEN
                SET @displayShow = "block"
                SET @displayShowMSO = "all"
            ELSE
                SET @displayShow = "none"
                SET @displayShowMSO = "none"
            ENDIF
            /* Dates */
            IF (@displayDate == "true") THEN
                SET @displayDate = "block"
                SET @displayDateMSO = "all"
            ELSE
                SET @displayDate = "none"
                SET @displayDateMSO = "none"
            ENDIF

            /* Copy */
            IF (@displayCopy == "true") THEN
                SET @displayCopy = "block"
                SET @displayCopyMSO = "all"
            ELSE
                SET @displayCopy = "none"
                SET @displayCopyMSO = "none"
            ENDIF

            /* Presale */
            IF (@displayPresale == "true") THEN
                SET @displayPresale = "block"
                SET @displayPresaleMSO = "all"
            ELSE
                SET @displayPresale = "none"
                SET @displayPresaleMSO = "none"
            ENDIF
            /* Code */
            IF (@displayCode == "true") THEN
                SET @displayCode = "block"
                SET @displayCodeMSO = "all"
            ELSE
                SET @displayCode = "none"
                SET @displayCodeMSO = "none"
            ENDIF
            /* On Sale */
            IF (@displayOnSale == "true") THEN
                SET @displayOnSale = "block"
                SET @displayOnSaleMSO = "all"
            ELSE
                SET @displayOnSale = "none"
                SET @displayOnSaleMSO = "none"
            ENDIF

            /* CTA Learn */
            IF (@displayCTALearn == "true") THEN
                SET @displayCTALearn = "block"
                SET @displayCTALearnMSO = "all"
            ELSE
                SET @displayCTALearn = "none"
                SET @displayCTALearnMSO = "none"
            ENDIF
            /* CTA Event */
            IF (@displayCTAEvent == "true") THEN
                SET @displayCTAEvent = "block"
                SET @displayCTAEventMSO = "all"
            ELSE
                SET @displayCTAEvent = "none"
                SET @displayCTAEventMSO = "none"
            ENDIF
            /* CTA Tickets */
            IF (@displayCTATickets == "true") THEN
                SET @displayCTATickets = "block"
                SET @displayCTATicketsMSO = "all"
            ELSE
                SET @displayCTATickets = "none"
                SET @displayCTATicketsMSO = "none"
            ENDIF

        ]%%`)
    }
    const superContent = () => {
        return ('<div style="font-family: Arial; width: 100%; padding: 5px 0 5px 0; background-color: #AF90E1; color: #222; font-size: 12px; font-weight: 700; letter-spacing: 2px; text-align: center;">' + blockTitle + ' | ' + stateData.name + '<br/><span style="font-size: 10px;">(this won\'t render)<span></div>');
    }
    useEffect(() => { // On Load
        sdk.setBlockEditorWidth(600); // Set Block Width
        sdk.getData((data) => {
            console.log("get data:", data);
            if (Object.keys(data).length === 0) return
            setStateData({ ...data });
        });
        sdk.setSuperContent(superContent());
    }, []);
    const onChangeValue = (e) => { // On Change Input
        console.log("on change value:", e.target.id, e.target.value);
        setStateData({ ...stateData, [e.target.id]: e.target.value });
    }
    const onChangeToggle = (e) => { // On Change Toggle
        console.log("on change toggle:", e.target.id, e.target.checked);
        setStateData({
            ...stateData,
            toggles: {
                ...stateData.toggles,
                [e.target.id.split('-')[1]]: e.target.checked
            }
        });
    }
    const getInclusionData = async (id) => {
        setLoading('visible');
        const response = await fetch(`https://mc13x7pm08rd2hw7jlz8ghg8szzm.pub.sfmc-content.com/4qasgduhqgv?id=${id}`)
        const result = await response.json();
        console.log(result);
        // RegEx
        const regExName = /(?<=\[Name\]:).*/;
        const showName = /(?<=\[Show\]:).*/;
        const regExDate = /(?<=\[Date\]:).*/;
        const regExCopy = /(?<=\[Copy\]:).*/;
        const regExPresale = /(?<=\[Presale\]:).*/;
        const regExCode = /(?<=\[Code\]:).*/;
        const regExOnSale = /(?<=\[OnSale\]:).*/;
        const regExCTALearn = /(?<=\[CTALearn\]:).*/;
        const regExCTALearnLink = /(?<=\[CTALearnLink\]:).*/;
        const regExCTAEvent = /(?<=\[CTAEvent\]:).*/;
        const regExCTAEventLink = /(?<=\[CTAEventLink\]:).*/;
        const regExCTATickets = /(?<=\[CTATickets\]:).*/;
        const regExCTATicketsLink = /(?<=\[CTATicketsLink\]:).*/;
        const regExImage480 = /(?<=\[Image480\]:).*/;
        const regExImage600 = /(?<=\[Image600\]:).*/;
        // Match
        const matchName = result[0].Description__c.match(regExName);
        const matchShow = result[0].Description__c.match(showName);
        const matchDate = result[0].Description__c.match(regExDate);
        const matchCopy = result[0].Description__c.match(regExCopy);
        const matchPresale = result[0].Description__c.match(regExPresale);
        const matchCode = result[0].Description__c.match(regExCode);
        const matchOnSale = result[0].Description__c.match(regExOnSale);
        const matchCTALearn = result[0].Description__c.match(regExCTALearn);
        const matchCTALearnLink = result[0].Description__c.match(regExCTALearnLink);
        const matchCTAEvent = result[0].Description__c.match(regExCTAEvent);
        const matchCTAEventLink = result[0].Description__c.match(regExCTAEventLink);
        const matchCTATickets = result[0].Description__c.match(regExCTATickets);
        const matchCTATicketsLink = result[0].Description__c.match(regExCTATicketsLink);
        const matchImage480 = result[0].Description__c.match(regExImage480);
        const matchImage600 = result[0].Description__c.match(regExImage600);
        // Trim
        const name = matchName ? matchName[0].trim() : '';
        const show = matchShow ? matchShow[0].trim() : '';
        const date = matchDate ? matchDate[0].trim() : '';
        const copy = matchCopy ? matchCopy[0].trim() : '';
        const presale = matchPresale ? matchPresale[0].trim() : '';
        const code = matchCode ? matchCode[0].trim() : '';
        const onSale = matchOnSale ? matchOnSale[0].trim() : '';
        const ctalearn = matchCTALearn ? matchCTALearn[0].trim() : '';
        const ctalearnLink = matchCTALearnLink ? matchCTALearnLink[0].trim() : '';
        const ctaEvent = matchCTAEvent ? matchCTAEvent[0].trim() : '';
        const ctaEventLink = matchCTAEventLink ? matchCTAEventLink[0].trim() : '';
        const ctaTickets = matchCTATickets ? matchCTATickets[0].trim() : '';
        const ctaTicketsLink = matchCTATicketsLink ? matchCTATicketsLink[0].trim() : '';
        const image480 = matchImage480 ? matchImage480[0].trim() : '';
        const image600 = matchImage600 ? matchImage600[0].trim() : '';
        // Set State
        setStateData({
            ...stateData,
            'name': name,
            'show': show,
            'date': date,
            'copy': copy,
            'presale': presale,
            'code': code,
            'onsale': onSale,
            'ctaLearn': ctalearn,
            'ctaLearnLink': ctalearnLink,
            'ctaEvent': ctaEvent,
            'ctaEventLink': ctaEventLink,
            'ctaTickets': ctaTickets,
            'ctaTicketsLink': ctaTicketsLink,
            'image480': image480,
            'image600': image600,
        });
        setLoading('hidden');
    }
    const setData = () => {
        console.log("set data:", stateData);
        sdk.setData({ ...stateData });
        sdk.setContent(content());
        sdk.setSuperContent(superContent());
    }
    //#endregion

    return (
        <div>

            <div className="slds-page-header__title" style={{ margin: 10 }}>
                {blockTitle}
            </div>

            {/* Main Info */}
            <Card heading="Retrieve from SF">
                <Input
                    label="Inclusion ID"
                    id="inclusionId"
                    onChange={onChangeValue}
                    value={stateData.inclusionId}
                    styleInput={{ textAlign: 'left' }}
                />
                <Button
                    label="Get Inclusion Data"
                    iconCategory="utility"
                    iconName="salesforce1"
                    iconPosition="left"
                    // disabled={stateData?.Id?.length != 18 || stateData?.Email_ID__c?.length === 0}
                    style={{ margin: 3 }}
                    onClick={() => getInclusionData(stateData.inclusionId)}
                />

            </Card>
            <Card heading="Main Info">
                <Input
                    label="Name"
                    id="name"
                    onChange={onChangeValue}
                    value={stateData.name}
                    styleInput={{ textAlign: 'left' }}
                    disabled={!stateData.toggles.name}
                >
                    <Checkbox
                        assistiveText={{
                            // label: 'Toggle (with assistive text)',
                        }}
                        id="toggle-name"
                        variant="toggle"
                        onChange={onChangeToggle}
                        checked={stateData.toggles.name}
                    />
                </Input>
                <Input
                    label="Show"
                    id="show"
                    onChange={onChangeValue}
                    value={stateData.show}
                    styleInput={{ textAlign: 'left' }}
                    disabled={!stateData.toggles.show}
                >
                    <Checkbox
                        assistiveText={{
                            label: 'Toggle (with assistive text)',
                        }}
                        id="toggle-show"
                        variant="toggle"
                        onChange={onChangeToggle}
                        checked={stateData.toggles.show}
                    />
                </Input>
                <Input
                    label="Date"
                    id="date"
                    onChange={onChangeValue}
                    value={stateData.date}
                    styleInput={{ textAlign: 'left' }}
                    disabled={!stateData.toggles.date}
                >
                    <Checkbox
                        assistiveText={{
                            label: 'Toggle (with assistive text)',
                        }}
                        id="toggle-date"
                        variant="toggle"
                        onChange={onChangeToggle}
                        checked={stateData.toggles.date}
                    />
                </Input>
            </Card>
            {/* Copy */}
            <Card heading="Copy">
                <Textarea id="copy" label="Copy" value={stateData.copy} onChange={onChangeValue} disabled={!stateData.toggles.copy}>
                <Checkbox
                    assistiveText={{
                        label: 'Toggle (with assistive text)',
                    }}
                    id="toggle-copy"
                    variant="toggle"
                    onChange={onChangeToggle}
                    checked={stateData.toggles.copy}
                        
                    />
                    </Textarea>
            </Card>
            {/* Pre / On Sale */}
            <Card heading="Presale, code, On Sale">
                <Input
                    label="Presale"
                    id="presale"
                    onChange={onChangeValue}
                    value={stateData.presale}
                    styleInput={{ textAlign: 'left' }}
                    disabled={!stateData.toggles.presale}
                >
                    <Checkbox
                        assistiveText={{
                            label: 'Toggle (with assistive text)',
                        }}
                        id="toggle-presale"
                        variant="toggle"
                        onChange={onChangeToggle}
                        checked={stateData.toggles.presale}
                    />
                </Input>
                <Input
                    label="Code"
                    id="code"
                    onChange={onChangeValue}
                    value={stateData.code}
                    styleInput={{ textAlign: 'left' }}
                    disabled={!stateData.toggles.code}
                >
                    <Checkbox
                        assistiveText={{
                            label: 'Toggle (with assistive text)',
                        }}
                        id="toggle-code"
                        variant="toggle"
                        onChange={onChangeToggle}
                        checked={stateData.toggles.code}
                    />
                </Input>
                <Input
                    label="On Sale"
                    id="onsale"
                    onChange={onChangeValue}
                    value={stateData.onsale}
                    styleInput={{ textAlign: 'left' }}
                    disabled={!stateData.toggles.onsale}
                >
                    <Checkbox
                        assistiveText={{
                            label: 'Toggle (with assistive text)',
                        }}
                        id="toggle-onsale"
                        variant="toggle"
                        onChange={onChangeToggle}
                        checked={stateData.toggles.onsale}
                    />
                </Input>
            </Card>
            {/* CTAs */}
            <Card heading="CTAs">
                <Input
                    label="CTA Learn"
                    id="ctaLearn"
                    onChange={onChangeValue}
                    value={stateData.ctaLearn}
                    styleInput={{ textAlign: 'left' }}
                    disabled={!stateData.toggles.ctaLearn}
                >
                    <Checkbox
                        assistiveText={{
                            label: 'Toggle (with assistive text)',
                        }}
                        id="toggle-ctaLearn"
                        variant="toggle"
                        onChange={onChangeToggle}
                        checked={stateData.toggles.ctaLearn}
                    />
                </Input>
                <Input
                    label="CTA Learn Link"
                    id="ctaLearnLink"
                    onChange={onChangeValue}
                    value={stateData.ctaLearnLink}
                    styleInput={{ textAlign: 'left' }}
                    disabled={!stateData.toggles.ctaLearn}
                />
                <Input
                    label="CTA Event"
                    id="ctaEvent"
                    onChange={onChangeValue}
                    value={stateData.ctaEvent}
                    styleInput={{ textAlign: 'left' }}
                    disabled={!stateData.toggles.ctaEvent}
                >
                    <Checkbox
                        assistiveText={{
                            label: 'Toggle (with assistive text)',
                        }}
                        id="toggle-ctaEvent"
                        variant="toggle"
                        onChange={onChangeToggle}
                        checked={stateData.toggles.ctaEvent}
                    />
                </Input>
                <Input
                    label="CTA Event Link"
                    id="ctaEventLink"
                    onChange={onChangeValue}
                    value={stateData.ctaEventLink}
                    styleInput={{ textAlign: 'left' }}
                    disabled={!stateData.toggles.ctaEvent}
                    />
                <Input
                    label="CTA Tickets"
                    id="ctaTickets"
                    onChange={onChangeValue}
                    value={stateData.ctaTickets}
                    styleInput={{ textAlign: 'left' }}
                    disabled={!stateData.toggles.ctaTickets}
                >
                    <Checkbox
                        assistiveText={{
                            label: 'Toggle (with assistive text)',
                        }}
                        id="toggle-ctaTickets"
                        variant="toggle"
                        onChange={onChangeToggle}
                        checked={stateData.toggles.ctaTickets}
                    />
                </Input>
                <Input
                    label="CTA Tickets Link"
                    id="ctaTicketsLink"
                    onChange={onChangeValue}
                    value={stateData.ctaTicketsLink}
                    styleInput={{ textAlign: 'left' }}
                    disabled={!stateData.toggles.ctaTickets}
                    />
            </Card>
            {/* Images */}
            <Card heading="Images">
                <Input
                    label="Image 480"
                    id="image480"
                    onChange={onChangeValue}
                    value={stateData.image480}
                    styleInput={{ textAlign: 'left' }}
                />
                <Input
                    label="Image 600"
                    id="image600"
                    onChange={onChangeValue}
                    value={stateData.image600}
                    styleInput={{ textAlign: 'left' }}
                />
            </Card>

            {/********** Save Button **********/}
            <Button
                label="Save Content"
                iconCategory="utility"
                iconName="save"
                iconPosition="left"
                // disabled={stateData?.Id?.length != 18 || stateData?.Email_ID__c?.length === 0}
                style={{ marginTop: 10 }}
                onClick={setData}
            />

            {/********** Loading Spinner **********/}
            <Spinner
                containerStyle={{ visibility: loading }}
                size="small"
                variant="brand"
                assistiveText={{ label: 'Main Frame Loading...' }}
            />
            {/********** Footer **********/}
            <FooterComponent />
        </div>
    )

}

export default TDG_IN_MainBlock_LR