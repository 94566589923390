
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './screens/home';
import SF_Import from './blocks/SF_Import/sf-import';
import Send_Test_Email from './blocks/Send_Test_Email/send-test-email';
import BRU_ClientWeeklyNewsletter_LRBlock from './blocks/BRU_ClientWeekly-Newsletter_LR-Block/bru-clientweekly-newsletter-lrblock.js';
// TDG Blocks
//import TDG_IN_Banner from './blocks/tdg-in-banner';
import TDG_Banner from './blocks/TDG_Banner/tdg-banner';
import TDG_IN_MainBlock_LR from './blocks/TDG_IN_MainBlock_LR/tdg-in-mainblock-lr';
import TDG_IN_Upcoming_x3 from './blocks/TDG_IN_Upcoming_x3/tdg-in-upcoming-x3';
import TDG_Suites_On_Sale from './blocks/TDG_Suites_On_Sale/tdg-suites-on-sale';
// Bruins Blocks
import BRU_KBYW_Upcoming_Games from './blocks/BRU_KBYW_Upcoming_Games/bru_kbyw_upcoming_games';
import Bruins_Authentics_Pucks_Teaser_3x2 from './blocks/Bruins_Authentics_Pucks_Teaser_3x2/bruins-authentics-pucks-teaser-3x2';
import BRU_Inclusion_Block from './blocks/BRU_Inclusion_Block/bru_inclusion_block.js';


function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="SF_Import.html" element={<SF_Import />} />
                <Route path="Send_Test_Email.html" element={<Send_Test_Email />} />

                <Route path="TDG_Banner.html" element={<TDG_Banner />} />
                <Route path="TDG_IN_MainBlock_LR.html" element={<TDG_IN_MainBlock_LR />} />
                <Route path="TDG_IN_Upcoming_x3.html" element={<TDG_IN_Upcoming_x3 />} />
                <Route path="TDG_Suites_On_Sale.html" element={<TDG_Suites_On_Sale />} />

                <Route path="BRU_KBYW_Upcoming_Games.html" element={<BRU_KBYW_Upcoming_Games />} />
                <Route path="BRU_ClientWeeklyNewsletter_LRBlock.html" element={<BRU_ClientWeeklyNewsletter_LRBlock />} />
                <Route path="Bruins_Authentics_Pucks_Teaser_3x2.html" element={<Bruins_Authentics_Pucks_Teaser_3x2 />} />
                <Route path="BRU_Inclusion_Block.html" element={<BRU_Inclusion_Block />} />
            </Routes>
        </div>
    );
}

export default App;
