//import { ReactDOM } from 'react';
import { useState, useEffect } from 'react';
import { Button, Card, Input, Textarea, PageHeader, Spinner } from '@salesforce/design-system-react';
import FooterComponent from '../../components/Footer';

var SDK = require('blocksdk');
var sdk = new SDK();

const BRU_KBYW_Upcoming_Games = () => {

    const blockTitle = 'BRU KBYW Upcoming Games';
    const [spinner, setSpinner] = useState('hidden');
    const [stateData, setStateData] = useState({
        abbr1: '',
        date1: '',
        time1: '',
        cta1: 'TICKETS',
        cta1url: '',
        //
        abbr2: '',
        date2: '',
        time2: '',
        cta2: 'TICKETS',
        cta2url: '',
        //
        abbr3: '',
        date3: '',
        time3: '',
        cta3: 'TICKETS',
        cta3url: ''
    });

    //#region main functions
    const content = () => {
        return (`
            <table width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation">
                <tr>
                    <td width="100%" style="padding: 10px; background-color: #000; color: #ddd; font-size: 18px; letter-spacing: 1px;">
                        UPCOMING GAMES
                    </td>
                </tr>
            </table>
        %%[
            SET @abbr1 = "${stateData.abbr1}"
            SET @abbr2 = "${stateData.abbr2}"
            SET @abbr3 = "${stateData.abbr3}"

            SET @date1 = "${stateData.date1}"
            SET @date2 = "${stateData.date2}"
            SET @date3 = "${stateData.date3}"

            SET @time1 = "${stateData.time1}"
            SET @time2 = "${stateData.time2}"
            SET @time3 = "${stateData.time3}"

            SET @cta1 = "${stateData.cta1}"
            SET @cta2 = "${stateData.cta2}"
            SET @cta3 = "${stateData.cta3}"

            SET @cta1url = "${stateData.cta1url}"
            SET @cta2url = "${stateData.cta2url}"
            SET @cta3url = "${stateData.cta3url}"

            SET @image1 = Lookup("ent.BRU_KBYW_Upcoming_Games", "Image_SRC", "Abbr", @abbr1)
            SET @image2 = Lookup("ent.BRU_KBYW_Upcoming_Games", "Image_SRC", "Abbr", @abbr2)
            SET @image3 = Lookup("ent.BRU_KBYW_Upcoming_Games", "Image_SRC", "Abbr", @abbr3)
        ]%%`)
    }
    const superContent = () => {
        return ('<div style="width: 100%; padding: 5px 0 5px 0; background-color: #000; color: #ddd; font-size: 12px; font-weight: 500; letter-spacing: 2px; text-align: center;">' + blockTitle + '<br/><span style="font-size: 10px;">(Render as "Upcoming Games")<span></div>');
    }
    useEffect(() => { // On Load
        sdk.setBlockEditorWidth(600); // Set Block Width
        sdk.getData((data) => {
            console.log("get data:", data);
            if (Object.keys(data).length === 0) return
            setStateData({ ...data });
        });
        sdk.setSuperContent(superContent());
    }, []);
    const onChangeValue = (e) => { // On Change Input
        console.log("on change value:", e.target.id, e.target.value);
        setStateData({ ...stateData, [e.target.id]: e.target.value });
    }
    const setData = () => {
        setSpinner('visible');
        console.log("set data:", stateData);
        sdk.setData({ ...stateData }, function (callback) {
            sdk.setContent(content(), function (callback) {
                sdk.setSuperContent(superContent(), function (callback) {
                    setSpinner('hidden');
                });
            });
        });
    }
    //#endregion

    return (
        <div>

            <div className="slds-page-header__title" style={{ margin: 10 }}>
                {blockTitle}
            </div>

            {/* Team 1 */}
            <Card heading="Team 1">
                <Input
                    label="Abbreviation"
                    id="abbr1"
                    placeholder="FLA"
                    onChange={onChangeValue}
                    value={stateData.abbr1}
                    styleInput={{ textAlign: 'left' }}
                />
                <Input
                    label="Date"
                    id="date1"
                    placeholder="APR. 1"
                    onChange={onChangeValue}
                    value={stateData.date1}
                    styleInput={{ textAlign: 'left' }}
                />
                <Input
                    label="Time"
                    id="time1"
                    placeholder="3:30PM"
                    onChange={onChangeValue}
                    value={stateData.time1}
                    styleInput={{ textAlign: 'left' }}
                />
                <Input
                    label="CTA"
                    id="cta1"
                    placeholder="TICKETS"
                    onChange={onChangeValue}
                    value={stateData.cta1}
                    styleInput={{ textAlign: 'left' }}
                />
                <Input
                    label="CTA URL"
                    id="cta1url"
                    placeholder="https://www.nhl.com/bruins/tickets"
                    onChange={onChangeValue}
                    value={stateData.cta1url}
                    styleInput={{ textAlign: 'left' }}
                />
            </Card>
            {/* Team 2 */}
            <Card heading="Team 2">
                <Input
                    label="Abbreviation"
                    id="abbr2"
                    placeholder="NYR"
                    onChange={onChangeValue}
                    value={stateData.abbr2}
                    styleInput={{ textAlign: 'left' }}
                />
                <Input
                    label="Date"
                    id="date2"
                    placeholder="APR. 4"
                    onChange={onChangeValue}
                    value={stateData.date2}
                    styleInput={{ textAlign: 'left' }}
                />
                <Input
                    label="Time"
                    id="time2"
                    placeholder="7PM"
                    onChange={onChangeValue}
                    value={stateData.time2}
                    styleInput={{ textAlign: 'left' }}
                />
                <Input
                    label="CTA"
                    id="cta2"
                    placeholder="TICKETS"
                    onChange={onChangeValue}
                    value={stateData.cta2}
                    styleInput={{ textAlign: 'left' }}
                />
                <Input
                    label="CTA URL"
                    id="cta2url"
                    placeholder="https://www.nhl.com/bruins/tickets"
                    onChange={onChangeValue}
                    value={stateData.cta2url}
                    styleInput={{ textAlign: 'left' }}
                />
            </Card>
            {/* Team 3 */}
            <Card heading="Team 3">
                <Input
                    label="Abbreviation"
                    id="abbr3"
                    placeholder="NYI"
                    onChange={onChangeValue}
                    value={stateData.abbr3}
                    styleInput={{ textAlign: 'left' }}
                />
                <Input
                    label="Date"
                    id="date3"
                    placeholder="APR. 5"
                    onChange={onChangeValue}
                    value={stateData.date3}
                    styleInput={{ textAlign: 'left' }}
                />
                <Input
                    label="Time"
                    id="time3"
                    placeholder="8PM"
                    onChange={onChangeValue}
                    value={stateData.time3}
                    styleInput={{ textAlign: 'left' }}
                />
                <Input
                    label="CTA"
                    id="cta3"
                    placeholder="TICKETS"
                    onChange={onChangeValue}
                    value={stateData.cta3}
                    styleInput={{ textAlign: 'left' }}
                />
                <Input
                    label="CTA URL"
                    id="cta3url"
                    placeholder="https://www.nhl.com/bruins/tickets"
                    onChange={onChangeValue}
                    value={stateData.cta3url}
                    styleInput={{ textAlign: 'left' }}
                />
            </Card>

            {/* Save Button */}
            <Button
                label="Save Content"
                iconCategory="utility"
                iconName="save"
                iconPosition="left"
                // disabled={stateData?.Id?.length != 18 || stateData?.Email_ID__c?.length === 0}
                style={{ marginTop: 10 }}
                onClick={setData}
            />

            {/********** Loading Spinner **********/}
            <Spinner
                containerStyle={{ visibility: spinner }}
                size="small"
                variant="brand"
                assistiveText={{ label: 'Main Frame Loading...' }}
            />
            <FooterComponent />
        </div>
    )

}

export default BRU_KBYW_Upcoming_Games