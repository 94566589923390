import React from 'react';
import metadata from '../metadata.json';
 
function FooterComponent() {
  return (
    <div className="sf-footer">
      &copy; 2024 Delaware North
      <div className="sf-footer-version">
        {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
      </div>
    </div>
  );
}
export default FooterComponent;