//import { ReactDOM } from 'react';
import { useState, useEffect } from 'react';
import { Button, Card, Checkbox, Input, Textarea, PageHeader, Spinner } from '@salesforce/design-system-react';
import FooterComponent from '../../components/Footer';

var SDK = require('blocksdk');
var sdk = new SDK();

const TDG_Suites_On_Sale = () => {

    const blockTitle = "TDG Suites On Sale";
    const [loading, setLoading] = useState('hidden');
    const [stateData, setStateData] = useState({
        requestId: '',
        name1: '',
        date1: '',
        ctaTickets1: '',
        ctaTicketsLink1: '',
        image1: '',
        //
        inclusionId2: '',
        name2: '',
        date2: '',
        ctaTickets2: '',
        ctaTicketsLink2: '',
        image2: '',
        //
        inclusionId3: '',
        name3: '',
        date3: '',
        ctaTickets3: '',
        ctaTicketsLink3: '',
        image3: '',
    });

    //#region main functions
    const content = () => {
        return (`%%[
            SET @name1 = "${stateData.name1}"
            SET @date1 = "${stateData.date1}"
            SET @ctaTickets1 = "${stateData.cta1}"
            SET @ctaTicketsLink1 = "${stateData.cta1url}"
            SET @image1 = "${stateData.image1}"
            
            SET @name2 = "${stateData.name2}"
            SET @date2 = "${stateData.date2}"
            SET @ctaTickets2 = "${stateData.cta2}"
            SET @ctaTicketsLink2 = "${stateData.cta2url}"
            SET @image2 = "${stateData.image2}"
            
            SET @name3 = "${stateData.name3}"
            SET @date3 = "${stateData.date3}"
            SET @ctaTickets3 = "${stateData.cta3}"
            SET @ctaTicketsLink3 = "${stateData.cta3url}"
            SET @image3 = "${stateData.image3}"
        ]%%`)
    }
    const superContent = () => {
        return ('<div style="width: 100%; padding: 5px 0 5px 0; background-color: #00C5B3; color: #333; font-size: 12px; font-weight: 500; letter-spacing: 2px; text-align: center;">'+blockTitle+'<br/><span style="font-size: 10px;">(this won\'t render)<span></div>');
    }
    useEffect(() => { // On Load
        sdk.setBlockEditorWidth(600); // Set Block Width
        sdk.getData((data) => {
            console.log("get data:", data);
            if (Object.keys(data).length === 0) return
            setStateData({ ...data });
        });
        sdk.setSuperContent(superContent());
    }, []);
    const onChangeValue = (e) => { // On Change Input
        console.log("on change value:", e.target.id, e.target.value);
        setStateData({ ...stateData, [e.target.id]: e.target.value });
    }
    const getInclusionData = async (id, num) => {
        setLoading('visible');
        const response = await fetch(`https://mc13x7pm08rd2hw7jlz8ghg8szzm.pub.sfmc-content.com/4qasgduhqgv?id=${id}`)
        const result = await response.json();
        // Regex
        const regExName = /(?<=\[Name\]:).*/;
        const regExDate = /(?<=\[DateUpcoming\]:).*/;
        const regExCTATickets = /(?<=\[CTATickets\]:).*/;
        const regExCTATicketsLink = /(?<=\[CTATicketsLink\]:).*/;
        const regExImage480 = /(?<=\[Image480\]:).*/;
        // Match
        const matchName = result[0].Description__c.match(regExName);
        const matchDate = result[0].Description__c.match(regExDate);
        const matchCTATickets = result[0].Description__c.match(regExCTATickets);
        const matchCTATicketsLink = result[0].Description__c.match(regExCTATicketsLink);
        const matchImage480 = result[0].Description__c.match(regExImage480);
        // Trim
        const name = matchName ? matchName[0].trim() : '';
        const date = matchDate ? matchDate[0].trim() : '';
        const ctaTickets = matchCTATickets ? matchCTATickets[0].trim() : '';
        const ctaTicketsLink = matchCTATicketsLink ? matchCTATicketsLink[0].trim() : '';
        const image480 = matchImage480 ? matchImage480[0].trim() : '';
        // Set State
        console.log(ctaTicketsLink);
        setStateData({
            ...stateData,
            [`name${num}`]: name,
            [`date${num}`]: date,
            [`ctaTickets${num}`]: ctaTickets,
            [`ctaTicketsLink${num}`]: ctaTicketsLink,
            [`image${num}`]: image480,
        });
        setLoading('hidden');
    }
    const setData = () => {
        setLoading('visible');
        console.log("set data:", stateData);
        sdk.setData({ ...stateData });
        sdk.setContent(content());
        sdk.setSuperContent(superContent());
        setLoading('hidden');
    }
    //#endregion

    return (
        <div>

            <div className="slds-page-header__title" style={{ margin: 10 }}>
                TDG IN Upcoming x3
            </div>

            {/* Upcoming 1 */}
            <Card heading="Upcoming 1">
                <Input
                    label="Inclusion ID"
                    id="inclusionId1"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.inclusionId1}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Button
                    label="Get Inclusion Data"
                    iconCategory="utility"
                    iconName="salesforce1"
                    iconPosition="left"
                    // disabled={stateData?.Id?.length != 18 || stateData?.Email_ID__c?.length === 0}
                    style={{ margin: 3 }}
                    onClick={() => getInclusionData(stateData.inclusionId1, "1")}
                />
                <Input
                    label="Name"
                    id="name1"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.name1}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Input
                    label="Date"
                    id="date1"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.date1}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Input
                    label="CTA"
                    id="ctaTickets1"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.ctaTickets1}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Input
                    label="URL"
                    id="ctaTicketsLink1"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.ctaTicketsLink1}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Input
                    label="Image"
                    id="image1"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.image1}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                    // iconRight={
                    //     <InputIcon
                    //         assistiveText={{
                    //             icon: 'Clear',
                    //         }}
                    //         name="upload"
                    //         category="utility"
                    //         size="x-small"
                    //         colorVariant="default"
                    //         onClick={(e) => {
                    //             console.log('Icon Clicked');
                    //         }}
                    //     />
                    // }
                />
                <Button
                    label="Save"
                    iconCategory="utility"
                    iconName="save"
                    iconPosition="left"
                    // disabled={stateData?.Id?.length != 18 || stateData?.Email_ID__c?.length === 0}
                    style={{ margin: 3 }}
                    onClick={setData}
                />
            </Card>

            {/* Upcoming 2 */}
            <Card heading="Upcoming 2">
                <Input
                    label="Inclusion ID"
                    id="inclusionId2"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.inclusionId2}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Button
                    label="Get Inclusion Data"
                    iconCategory="utility"
                    iconName="salesforce1"
                    iconPosition="left"
                    // disabled={stateData?.Id?.length != 18 || stateData?.Email_ID__c?.length === 0}
                    style={{ margin: 3 }}
                    onClick={() => getInclusionData(stateData.inclusionId2, "2")}
                />
                <Input
                    label="Name"
                    id="name2"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.name2}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />

                <Input
                    label="Date"
                    id="date2"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.date2}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Input
                    label="CTA"
                    id="ctaTickets2"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.ctaTickets2}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Input
                    label="URL"
                    id="ctaTicketsLink2"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.ctaTicketsLink2}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Input
                    label="Image"
                    id="image2"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.image2}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Button
                    label="Save"
                    iconCategory="utility"
                    iconName="save"
                    iconPosition="left"
                    // disabled={stateData?.Id?.length != 18 || stateData?.Email_ID__c?.length === 0}
                    style={{ margin: 3 }}
                    onClick={setData}
                />
            </Card>
            {/* Upcoming 3 */}
            <Card heading="Upcoming 3">
            <Input
                    label="Inclusion ID"
                    id="inclusionId3"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.inclusionId3}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Button
                    label="Get Inclusion Data"
                    iconCategory="utility"
                    iconName="salesforce1"
                    iconPosition="left"
                    // disabled={stateData?.Id?.length != 18 || stateData?.Email_ID__c?.length === 0}
                    style={{ margin: 3 }}
                    onClick={() => getInclusionData(stateData.inclusionId3, "3")}
                />
                <Input
                    label="Name"
                    id="name3"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.name3}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Input
                    label="Date"
                    id="date3"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.date3}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Input
                    label="CTA"
                    id="ctaTickets3"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.ctaTickets3}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Input
                    label="URL"
                    id="ctaTicketsLink3"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.ctaTicketsLink3}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                 <Input
                    label="Image"
                    id="image3"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.image3}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Button
                    label="Save"
                    iconCategory="utility"
                    iconName="save"
                    iconPosition="left"
                    // disabled={stateData?.Id?.length != 18 || stateData?.Email_ID__c?.length === 0}
                    style={{ margin: 3 }}
                    onClick={setData}
                />
            </Card>

            {/********** Loading Spinner **********/}
            <Spinner
                containerStyle={{ visibility: loading }}
                size="small"
                variant="brand"
                assistiveText={{ label: 'Main Frame Loading...' }}
            />
            {/********** Footer **********/}
            <FooterComponent />
        </div>
    )

}

export default TDG_Suites_On_Sale