import { Link } from 'react-router-dom'

const Home = () => {

    return (
        <div>
            <h3>Global</h3>
            <Link to="SF_Import.html">SF Import</Link><br/>
            <Link to="Send_Test_Email.html">Send Test Email</Link><br/>
            <br/>
            <h3>TD Garden</h3>
            <Link to="TDG_Banner.html">TDG Banner</Link><br/>
            <Link to="TDG_IN_MainBlock_LR.html">TDG IN Main Block LR</Link><br/>
            <Link to="TDG_IN_Upcoming_x3.html">TDG IN Upcoming x3</Link><br/>
            <Link to="TDG_Suites_On_Sale.html">TDG Suites On Sale</Link><br/>
            <br/>
            <h3>Boston Bruins</h3>
            <Link to="BRU_KBYW_Upcoming_Games.html">Bruins Upcoming Games</Link><br/>
            <Link to="BRU_ClientWeeklyNewsletter_LRBlock.html">Bruins Client Weekly Newsletter LR Block</Link><br/>
            <Link to="Bruins_Authentics_Pucks_Teaser_3x2.html">Bruins Authentics Pucks Teaser 3x2 Block</Link><br/>
            <Link to="BRU_Inclusion_Block.html">Bruins Inclusion Block</Link><br/>
        </div>
    )
}

export default Home