// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

import React from 'react';
//import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import "@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system.min.css"
import { IconSettings } from '@salesforce/design-system-react/';

ReactDOM.createRoot(
    document.getElementById("root"),
)
    .render(
        <BrowserRouter>
            <IconSettings iconPath="/icons/">
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            </IconSettings>
        </BrowserRouter>,
    );


// ReactDOM.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
