//import { ReactDOM } from 'react';
import { useState, useEffect } from 'react';
import { Button, Card, Input, RadioGroup, Radio, Textarea, PageHeader, Spinner } from '@salesforce/design-system-react';
import FooterComponent from '../../components/Footer';
import { pauseTrigger, updateTrigger, publishTrigger, startTrigger, sendEmail } from '../../helpers/testEmail';

var SDK = require('blocksdk');
var sdk = new SDK();

const Send_Test_Email = () => {

    const blockTitle = 'Send Test Email';
    const [spinner, setSpinner] = useState('hidden');

    const businessUnits = ['BRU', 'TDG'];
    const senderProfilesBRU = ['Boston Bruins', 'Boston Bruins Fan Relations', 'Boston Bruins Group Sales', 'Boston Bruins Hertitage Hall', 'Boston Bruins Ticket Sales', 'Boston Bruins Youth Hockey', 'Boston Garden Society', 'Boston Pro Shop', 'Bruins Authentics', 'Bruins Foundation', 'Default', 'Dynamic Form Display Non-Rep', 'GPH Dynaic Display From Acct Rep', 'Rep Name Dynamic to DE NOT STH or GPH', 'STH Dynamic Display From Acct Rep', 'TD Garden', 'TD Garden - STH']; //Rep Name Dynamic to DE NOT STH or GPH - Rep Email Included in Live Data
    const senderProfilesTDG = ['BGS', 'BGS Personalized Rep', 'Default', 'Rafters', 'TD Garden', 'VIP Ticketing'];

    const [stateData, setStateData] = useState({
        businessUnit: 'Boston Bruins',
        senderProfile: 'Boston Bruins',
        emailId: '',
        subjectLine: ''
    });

    //#region main functions
    // const content = () => {
    //     return (`
    //         <table width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation">
    //             <tr>
    //                 <td width="100%" style="padding: 20px 0 0 0;">
    //                     <table width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation">
    //                         <tr>
    //                             <td width="100%" style="padding: 8px; background-color: ${stateData.backgroundColor}; color: ${stateData.textColor}; font-size:16px; letter-spacing:2px; letter-spacing: 1px; font-weight: 700; text-align: center;">
    //                                 ${stateData.text}
    //                             </td>
    //                         </tr>
    //                     </table>
    //                 </td>
    //             </tr>
    //         </table>
    //     `)
    // }
    const superContent = () => {
        return ('<div style="width: 100%; padding: 5px 0 5px 0; background-color: #2990B2; color: #fff; font-size: 12px; font-weight: 500; letter-spacing: 2px; text-align: center;">' + blockTitle + '<br/><span style="font-size: 10px;">(this won\'t render)<span></div>');
    }
    useEffect(() => { // On Load
        sdk.setBlockEditorWidth(600); // Set Block Width
        sdk.getView((view) => { // Set View
            console.log("get view:", view);
        });
        sdk.getCentralData((data) => {
            console.log("get central data:", data);
        });
        sdk.getData((data) => {
            console.log("get data:", data);
            if (Object.keys(data).length === 0) return
            setStateData({ ...data });
        });
    }, []);
    const onChangeValue = (e) => { // On Change Input
        console.log("on change value:", e.target.id, e.target.value);
        setStateData({ ...stateData, [e.target.id]: e.target.value });
    }
    useEffect(() => {
        console.log(stateData);
        sdk.setData({ ...stateData });
        // sdk.setContent(content());
        sdk.setSuperContent(superContent());
    }, [stateData])
    // const setData = () => {
    //     console.log("set data:", stateData);
    //     sdk.setData({ ...stateData });
    //     sdk.setContent(content());
    //     // sdk.setSuperContent(superContent(), function(callback) {
    //     //     setSpinner('hidden');
    //     // });
    // }
    //#endregion

    const sendTestEmail = async () => {
        setSpinner('visible');
        // ---
        const pause = await pauseTrigger(stateData.businessUnit);
        console.log("pause:", pause);
        if (pause !== "OK") return;
        const update = await updateTrigger(stateData.businessUnit, stateData.emailId, stateData.subjectLine); // 76816
        console.log("update:", update);
        if (update !== "OK") return;
        const publish = await publishTrigger(stateData.businessUnit);
        console.log("publish:", publish);
        if (publish !== "OK") return;
        const start = await startTrigger(stateData.businessUnit);
        console.log("start:", start);
        if (start !== "OK") return;
        const send = await sendEmail(stateData.businessUnit);
        console.log("email:", send);
        // ---
        setSpinner('hidden');
    }

    /*

    "ExactTarget.OMM.FunctionExecutionException: Error executing update call.
  Error Code: OMM_FUNC_CONTEXT_ERR
 - from Jint --> 

 --- inner exception 1---

System.ArgumentException: Object of type 'System.String' cannot be converted to type 'ExactTarget.Integration.WSDL.SenderProfile'. - from mscorlib
https://members.s11.exacttarget.com/Content/Administration/SendManagement/SenderProfile.aspx?profileid=5a910145-0384-ee11-a5cc-d4f5ef66aeb2
"ExactTarget.OMM.FunctionExecutionException: Error executing update call.
  Error Code: OMM_FUNC_CONTEXT_ERR
 - from Jint --> 

 --- inner exception 1---

ExactTarget.OMM.InvalidFunctionParameterException: Invalid property name: Status
  Error Code: OMM_FUNC_CONTEXT_ERR
 - from OMMCommon



"

"

      */

    return (
        <div>

            <div className="slds-page-header__title" style={{ margin: 10 }}>
                {blockTitle}
            </div>

            {/* Main Info */}
            <Card heading="Send Test">
                <RadioGroup
                    labels={{ label: 'Business Unit' }}
                    onChange={(event) => onChangeValue({ target: { id: 'businessUnit', value: event.target.value } })}
                    disabled={false}
                    required={false}
                    name={'businessUnit'}
                    style={{ flexDirection: 'column' }}
                    styleContainer={{ flexDirection: 'column' }}
                //errorId={f}
                >
                    {businessUnits.map((value, index) => (
                        <Radio
                            className="column"
                            key={value}
                            id={value}
                            labels={{ label: value }}
                            value={value}
                            checked={stateData.businessUnit === value}
                            variant="base"
                        />
                    ))}
                </RadioGroup>
                <hr style={{ margin: 10 }} />
                <RadioGroup
                    labels={{ label: 'Sender Profile' }}
                    onChange={(event) => onChangeValue({ target: { id: 'senderProfile', value: event.target.value } })}
                    disabled={false}
                    required={false}
                    name={'senderProfile'}
                    style={{ flexDirection: 'column' }}
                    styleContainer={{ flexDirection: 'column' }}
                //errorId={f}
                >
                    {stateData.businessUnit === 'BRU' ? senderProfilesBRU.map((value, index) => (
                        <Radio
                            className="column"
                            key={value}
                            id={value}
                            labels={{ label: value }}
                            value={value}
                            checked={stateData.senderProfile === value}
                            variant="base"
                        />
                    )) : senderProfilesTDG.map((value, index) => (
                        <Radio
                            className="column"
                            key={value}
                            id={value}
                            labels={{ label: value }}
                            value={value}
                            checked={stateData.senderProfile === value}
                            variant="base"
                        />
                    ))}
                </RadioGroup>
                <hr style={{ margin: 10 }} />
                <Input
                    label="Email Id"
                    id="emailId"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.emailId}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <Input
                    label="Subject Line"
                    id="subjectLine"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.subjectLine}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />

                <Button
                    label="Send Test Email"
                    iconCategory="utility"
                    iconName="email"
                    iconPosition="left"
                    // disabled={stateData?.Id?.length != 18 || stateData?.Email_ID__c?.length === 0}
                    style={{ marginTop: 10 }}
                    onClick={() => sendTestEmail()}
                />

            </Card>

            {/* Save Button */}
            {/* <Button
                label="Save Content"
                iconCategory="utility"
                iconName="save"
                iconPosition="left"
                // disabled={stateData?.Id?.length != 18 || stateData?.Email_ID__c?.length === 0}
                style={{ marginTop: 10 }}
                onClick={setData}
            /> */}
            {/********** Loading Spinner **********/}
            <Spinner
                containerStyle={{ visibility: spinner }}
                size="small"
                variant="brand"
                assistiveText={{ label: 'Main Frame Loading...' }}
            />

            <FooterComponent />
        </div>
    )

}

export default Send_Test_Email