import process from "process";

// Auth
const sfmcAuth = async () => {

    const response = await fetch('http://127.0.0.1:5000/auth');
    const result = await response.text();
    console.log(result);
    return result;
}

export default sfmcAuth;

// CREATE Asset Image
export const createAssetImage = async (token, image, name, folderId, parentFolderId) => {

    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
           
            payload: {
                "name": name,
                "assetType": {
                    "name": "jpg",
                    "id": 23
                },
                "category": {
                    "id": parentFolderId,
                    "name": "Client Weekly/BNL",
                    "parentId": folderId
                },
                "FileProperties": {
                    "fileName": name
                },
                "file": image    
            },
            token: token

        })
    }
    const response = await fetch('http://127.0.0.1:5000/create-asset-image', options);
    const result = await response.json();
    return result;
}

// Get Asset by Id
const getAssetByID = async (token, assetID) => {

    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token }
    };

    const url = 'https://' + process.env.ACCOUNT_ID_BRU + '.rest.marketingcloudapis.com/asset/v1/content/assets/${assetID}';
    const response = await fetch(url, options);
    const result = await response.json();
   
    return result;
}

