//import { ReactDOM } from 'react';
import { useState, useEffect, useCallback } from 'react';
import process from "process";
import { Button, Card, Input, RadioGroup, Radio, Textarea, PageHeader, Spinner } from '@salesforce/design-system-react';
import { useDropzone } from 'react-dropzone'
import FooterComponent from '../../components/Footer';
import sfmcAuth, { createAssetImage } from '../../helpers/sfmc';

const BlockSDK = require('blocksdk');
const sdk = new BlockSDK(['stylingblock', 'htmlblock']);

const BRU_ClientWeeklyNewsletter_LRBlock = () => {

    useEffect(() => {
        const url = document.URL;
        const referrer = document.referrer;
        const env = process.env.NODE_ENV;
        setEnv(env);
        if (env === 'development') {
            return
        }
        if (env === 'production') {
            if (!referrer.includes(process.env.REFERRER)) {
                console.log("not authorized");
            } else {
                console.log("authorized");
            }
        }
    }, [])

    const blockTitle = 'BRU Client Weekly / Newsletter - LR Block';
    const [spinner, setSpinner] = useState('hidden');
    const [env, setEnv] = useState('');
    const [stateData, setStateData] = useState({
        hl: '',
        copy: '',
        cta: '',
        // url or mailto
        dataLinkTo: 'https://',
        url: '',
        subjectLine: '',
        // image
        parentFolderId: 107532,
        folderName: 'Client Weekly/BNL',
        folderId: 107535,
        fileName: '',
        base64: '',
        src: '',
        imageId: 360142, // default placeholder image
    });
    const dataLinkTo = ['https://', 'mailto:'];

    //#region main functions
    const content = () => {
        return (`
        <table cellspacing="0" cellpadding="0" role="presentation" style="width: 100%;">
            <tr>
                <td valign="top" width="100%" style="width: 100%; padding: 10px 10px 20px 10px;">
                    <table cellspacing="0" cellpadding="0" role="presentation" style="width: 100%;">
                        <tr>
                            <td valign="top" className="responsive-td" style="width: 40%; padding-right: 5px;">
                                <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="min-width: 100%; " className="stylingblock-content-wrapper">
                                    <tr>
                                        <td className="stylingblock-content-wrapper camarker-inner">
                                            <table width="100%" cellspacing="0" cellpadding="0" role="presentation">
                                                <tr>
                                                    <td align="center">
                                                        <img data-assetid="${stateData.imageId}" src="${stateData.src}" alt="${stateData.hl}" width="100%" style="display: block; padding: 0px; text-align: center; height: auto; width: 100%; border: 0px;" />
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td valign="top" className="responsive-td" style="width: 60%; padding-left: 5px;">
                                <table cellpadding="0" cellspacing="0" width="100%" role="presentation" className="stylingblock-content-wrapper" style="min-width: 100%; ">
                                    <tr>
                                        <td className="stylingblock-content-margin-cell" style="padding: 5px 0px 0px; ">
                                            <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="background-color: transparent; min-width: 100%; " className="stylingblock-content-wrapper">
                                                <tr>
                                                    <td style="padding: 0px 0px 0px 10px; " className="stylingblock-content-wrapper camarker-inner">
                                                        <table align="left" cellpadding="0" cellspacing="0" role="presentation" width="100%">
            
                                                            <tr>
                                                                <td align="left" style="padding: 0 0 20px 0; text-align:left; line-height:125%; font-size:18px; font-family:Arial,Helvetica,sans-serif; font-weight:700;" width="100%">
                                                                    ${stateData.hl}
                                                                </td>
                                                            </tr>
                                                        
                                                            <tr>
                                                                <td align="left" style="padding: 0 0 10px 0; text-align:left; line-height:115%; font-size:14px; font-family:Arial,Helvetica,sans-serif; font-weight:500;" width="100%">
                                                                    ${stateData.copy}
                                                                </td>
                                                            </tr>
                                                    
                                                            <tr>
                                                                <td width="100%" align="left" style="padding: 8px 0 8px 0px;">
                                                                    <table border="0" cellspacing="0" cellpadding="0" role="presentation">
                                                                        <tr>
                                                                            <td className="innertd buttonblock" bgcolor="#FBC22D" style=" border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; color: #000001; background-color: #FBC22D;">
                                                                                <a style=" font-size: 16px; font-family: Impact, Charcoal, sans-serif; color: #000001; text-align: left; text-decoration: none; display: block; font-weight: normal; letter-spacing: 1px; line-height: 100%; background-color: #FBC22D; border: 1px solid transparent; padding: 10px; border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; text-transform: uppercase;" target="_blank" className="buttonstyles" href="${stateData.url}" title="" alias="" conversion="false" data-linkto="${stateData.dataLinkTo}">${stateData.cta}</a>
                                                                            </td>     
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
        `)
    }
    // const superContent = () => {
    //     return ('<div style="width: 100%; padding: 5px 0 5px 0; background-color: #2990B2; color: #fff; font-size: 12px; font-weight: 500; letter-spacing: 2px; text-align: center;">' + blockTitle + '<br/><span style="font-size: 10px;">(this won\'t render)<span></div>');
    // }
    useEffect(() => { // On Load
        sdk.setBlockEditorWidth(600); // Set Block Width
        sdk.getView((view) => { // Set View
            console.log("get view:", view);
        });
        sdk.getCentralData((data) => {
            console.log("get central data:", data);
        });
        sdk.getData((data) => {
            console.log("get data:", data);
            if (Object.keys(data).length === 0) return
            setStateData({ ...data });
        });
    }, []);
    const onChangeValue = (e) => { // On Change Input
        console.log("on change value:", e.target.id, e.target.value);
        setStateData({ ...stateData, [e.target.id]: e.target.value });
    }
    const onDrop = useCallback(acceptedFiles => {
        handleImage(acceptedFiles[0]);
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleImage = async (file) => {
        var name = file.name.replace(/ /g, "_");
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            console.log(reader.result);
            setStateData({
                ...stateData,
                fileName: name,
                base64: reader.result,
            });
            uploadImage(reader.result, name);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    const uploadImage = async (base64, name) => {
        setSpinner('visible');
        const token = await sfmcAuth();
        const response = await createAssetImage(token, base64, name, stateData.folderId, stateData.parentFolderId);
        console.log('response:', response);
        if (response.fileProperties.publishedURL !== undefined || response.fileProperties.publishedURL !== null) {
            setStateData({
                ...stateData,
                src: response.fileProperties.publishedURL,
                imageId: response.id
            });
        }
        setSpinner('hidden');
    }
    // save data automatically when changed
    useEffect(() => {
        sdk.setData({ ...stateData });
        sdk.setContent(content());
    }, [stateData])

    return (
        <div>

            <div className="slds-page-header__title" style={{ margin: 10 }}>
                {blockTitle}
            </div>

            {/* Main Info */}
            <Card heading="Content">
                {/********** Text **********/}
                <Input label="HL" id="hl" value={stateData.hl} onChange={onChangeValue} styleInput={{ textAlign: 'left' }} />
                <Textarea label="Copy" id="copy" value={stateData.copy} onChange={onChangeValue} styleInput={{ textAlign: 'left' }} />
                <Input
                    label="CTA"
                    id="cta"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.cta}
                    styleInput={{ textAlign: 'left' }}
                />
                <RadioGroup
                    labels={{ label: 'Data Link To' }}
                    onChange={(event) => onChangeValue({ target: { id: 'dataLinkTo', value: event.target.value } })}
                    disabled={false}
                    required={false}
                    //name={false}
                    style={{ flexDirection: 'column' }}
                    styleContainer={{ flexDirection: 'column' }}
                >
                    {dataLinkTo.map((value, index) => (
                        <Radio
                            className="column"
                            key={value}
                            id={value}
                            labels={{ label: dataLinkTo[index] }}
                            value={value}
                            checked={stateData.dataLinkTo === value}
                            variant="base"
                        />
                    ))}
                </RadioGroup>
                {/* URL */}
                <Input label="URL" id="url" value={stateData.url} onChange={onChangeValue} styleInput={{ textAlign: 'left' }} disabled={stateData.dataLinkTo == 'https://' ? false : true} />
                {/* Subject Line */}
                <Input label="Subject Line" id="subjectLine" value={stateData.subjectLine} onChange={onChangeValue} styleInput={{ textAlign: 'left' }} disabled={stateData.dataLinkTo == 'mailto:' ? false : true} />
                {/* SRC */}
                <Input label="SRC" id="src" value={stateData.src} onChange={onChangeValue} styleInput={{ textAlign: 'left' }} />
                {/* IMAGE */}
                <div className="slds-form-element" style={{ margin: 3, justifyContent: 'flex-start' }}>
                    <label className="slds-form-element__label" htmlFor="image">Image</label>
                    <div className="slds-form-element__control" id="image" style={{ justifySelf: 'flex-start', height: 240, maxWidth: 240, backgroundColor: '#fff', boxShadow: '0px 2px 6px rgba(0,0,0,0.16)' }} {...getRootProps()}>
                        {stateData.base64 && <img style={{ position: 'absolute', width: 240, height: 240 }} src={stateData.base64} />}
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <p>Drop the files here ...</p> :
                                <p>Drag 'n' drop some files here, or click to select files</p>
                        }
                    </div>
                </div>
                {/* Name */}
                <Input label="Name" id="fileName" value={stateData.fileName} onChange={onChangeValue} styleInput={{ textAlign: 'left' }} disabled />
                {/* Base64 */}
                <Textarea label="Base64" id="base64" value={stateData.base64} onChange={onChangeValue} styleInput={{ textAlign: 'left' }} disabled />

            </Card>

            <Card heading="Preview">
                <div dangerouslySetInnerHTML={{ __html: content() }} />
            </Card>

            {/********** Loading Spinner **********/}
            <Spinner
                containerStyle={{ visibility: spinner }}
                size="small"
                variant="brand"
                assistiveText={{ label: 'Main Frame Loading...' }}
            />
            <FooterComponent />
        </div>
    )

}

export default BRU_ClientWeeklyNewsletter_LRBlock