//import { ReactDOM } from 'react';
import { useState, useEffect } from 'react';
import { Button, Card, Input, PageHeader, Spinner } from '@salesforce/design-system-react';
import FooterComponent from '../../components/Footer';

var SDK = require('blocksdk');
var sdk = new SDK();

const Bruins_Authentics_Pucks_Teaser_3x2 = () => {

    const [stateData, setStateData] = useState({
        playerName1: '',
        gameDate1: '',
        playerName2: '',
        gameDate2: '',
        playerName3: '',
        gameDate3: '',
        playerName4: '',
        gameDate4: '',
        playerName5: '',
        gameDate5: '',
        playerName6: '',
        gameDate6: '',
    });

    const content = () => {
        return (`%%[
            SET @playerName1 = "${stateData.playerName1}"
            SET @gameDate1 = "${stateData.gameDate1}"
            SET @playerName2 = "${stateData.playerName2}"
            SET @gameDate2 = "${stateData.gameDate2}"
            SET @playerName3 = "${stateData.playerName3}"
            SET @gameDate3 = "${stateData.gameDate3}"
            SET @playerName4 = "${stateData.playerName4}"
            SET @gameDate4 = "${stateData.gameDate4}"
            SET @playerName5 = "${stateData.playerName5}"
            SET @gameDate5 = "${stateData.gameDate5}"
            SET @playerName6 = "${stateData.playerName6}"
            SET @gameDate6 = "${stateData.gameDate6}"
        ]%%`)
    }
    const superContent = () => {
        return ('<div style="width: 100%; padding: 5px 0 5px 0; background-color: #2990B2; color: #fff; font-size: 12px; font-weight: 500; letter-spacing: 2px; text-align: center;">Pucks Teaser 3x2<br/><span style="font-size: 10px;">(this won\'t render)<span></div>');
    }

    useEffect(() => { // On Load
        sdk.setBlockEditorWidth(600); // Set Block Width
        sdk.getData((data) => {
            console.log("get data:", data);
            if (Object.keys(data).length === 0) return
            setStateData({ ...data });
        });
        sdk.setSuperContent(superContent());
    }, []);

    const onChangeValue = (e) => { // On Change Input
        console.log("on change value:", e.target.id, e.target.value);
        setStateData({ ...stateData, [e.target.id]: e.target.value });
    }
    const setData = () => {
        console.log("set data:", stateData);
        sdk.setData({ ...stateData });
        sdk.setContent(content());
        sdk.setSuperContent(superContent());
    }

    return (
        <div>

            <div className="slds-page-header__title" style={{ margin: 10 }}>
                Bruins Authentics Pucks Teaser 3x2
            </div>

            <Card heading="Player 1" >
                <Input
                    label="Player Name 1"
                    id="playerName1"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.playerName1}
                    styleInput={{ textAlign: 'left'}}
                    styleContainer={{ }}
                />
                <Input
                    label="Game Date 1"
                    id="gameDate1"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.gameDate1}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
            </Card>

            <Card heading="Player 2">
                <Input
                    label="Player Name 2"
                    id="playerName2"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.playerName2}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{ }}
                />
                <Input
                    label="Game Date 2"
                    id="gameDate2"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.gameDate2}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{ }}
                />
            </Card>

            <Card heading="Player 3">
                <Input
                    label="Player Name 3"
                    id="playerName3"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.playerName3}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{ }}
                />
                <Input
                    label="Game Date 3"
                    id="gameDate3"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.gameDate3}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{ }}
                />
            </Card>

            <Card heading="Player 4">
                <Input
                    label="Player Name 4"
                    id="playerName4"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.playerName4}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{ }}
                />
                <Input
                    label="Game Date 4"
                    id="gameDate4"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.gameDate4}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{ }}
                />
            </Card>

            <Card heading="Player 5">
                <Input
                    label="Player Name 5"
                    id="playerName5"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.playerName5}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{ }}
                />
                <Input
                    label="Game Date 5"
                    id="gameDate5"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.gameDate5}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{ }}
                />
            </Card>

            <Card heading="Player 6">
                <Input
                    label="Player Name 6"
                    id="playerName6"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.playerName6}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{ }}
                />
                <Input
                    label="Game Date 6"
                    id="gameDate6"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.gameDate6}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{ }}
                />
            </Card>

            <Button
                label="Save Content"
                iconCategory="utility"
                iconName="save"
                iconPosition="left"
                // disabled={stateData?.Id?.length != 18 || stateData?.Email_ID__c?.length === 0}
                style={{ marginTop: 10 }}
                onClick={setData}
            />

            <FooterComponent />
        </div>
    )

}

export default Bruins_Authentics_Pucks_Teaser_3x2