import { useState, useEffect } from "react"


const BRU_Inclusion_Block = () => {

    var SDK = require('blocksdk');
    var sdk = new SDK();

    useEffect(() => {
        const url = document.URL;
        const referrer = document.referrer;
        const env = process.env.NODE_ENV;
        if (env === 'development') {
            return
        }
        if (env === 'production') {
            if (!referrer.includes(process.env.REFERRER)) {
                console.log("not authorized");
            } else {
                console.log("authorized");
            }
        }
    }, []);

    const blockTitle = 'BRU Inclusion Block';
    const [spinner, setSpinner] = useState('hidden');
    const [stateData, setStateData] = useState({
        asset: {},
    });

    useEffect(() => {
        //const url = "../../sfmc_api/sfmc_auth.php";
        const auth = async () => {
            const result = await fetch("./sfmc_api/sfmc_auth.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ bu: "bru" })
            });
            const token = await result.text();
            console.log(token);
            const result2 = await fetch("./sfmc_api/sfmc_get_asset_by_id.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    access_token: token,
                    asset_id: "364614"
                })
            });
            const assetJSON = await result2.text();
            const asset = JSON.parse(assetJSON);
            console.log(asset);
            setStateData({ asset: asset });
        }
        auth();
    }, [])

    useEffect(() => {
        console.log(stateData.asset);
        if (stateData.asset?.content) {
            const assetData = {
                inclusionName: "name",
                hl: "headline",
                body: "body",
                cta: "cta",
                ctaLink: "ctaurl"
            };
            setContent(assetData, "inclusion");
        }
    }, [stateData])

    const regExes = {
        inclusion: [
            { hl: /~HL~/gim },
            { body: /~BODY~/gim },
            { cta: /~CTA~/gim },
            { ctaLink: /~HREF~/gim },
            { hl: /~ALT~/gim },
            { disclaimer: /~DISCLAIMER~/gim }
        ],
        event: [
            { event_name_long: /~NAME~/gim },
            { team: /~SHOW~/gim },
            { event_date: /~DATE~/gim },
            { event_time: /~TIME~/gim },
            { cta: /~CTA~/gim }
        ]
    };

    const setContent = (assetData, type) => {
        const previewAsset = document.getElementById("previewAsset");
        previewAsset.innerHTML = stateData.asset.content;
        // regex for slots and blocks
        const regEx = /(?<=data-key=").*?(?=")/gm;
        // slots
        const slotNames = previewAsset.innerHTML.match(regEx); // asset.slots["name"]
        const slots = previewAsset.querySelectorAll("[data-key]"); // asset.slots.
        console.log("slots", slotNames, slots);

        if (slots.length > 0) {
            slots.forEach((element, i) => {
                element.innerHTML = stateData.asset.slots[slotNames[i]].content;
                const blockNames = element.innerHTML.match(regEx);
                const blocks = element.querySelectorAll("[data-key]");

                blockNames.forEach((element, j) => {
                    regExes[type].forEach((regEx) => {
                        Object.keys(regEx).forEach((key) => {
                            stateData.asset.slots[slotNames[i]].blocks[element].content =
                                stateData.asset.slots[slotNames[i]].blocks[
                                    element
                                ].content.replace(regEx[key], assetData[key]);
                        });
                    });
                });

                // change new asset second
                blocks.forEach((block, j) => {
                    block.innerHTML =
                        stateData.asset.slots[slotNames[i]].blocks[blockNames[j]].content;

                    const image = block.querySelector("img");
                    if (image) {
                        // image.src = imagePlaceholder480;
                    }
                });
            });
        } else {
            regExes[type].forEach((regEx) => {
                Object.keys(regEx).forEach((key) => {
                    stateData.asset.content = stateData.asset.content.replace(
                        regEx[key],
                        assetData[key]
                    );
                });
            });
            // change new asset secont
            const image = previewAsset.querySelector("img");
            if (image) {
                // image.src = imagePlaceholder480;
            }
        }

        sdk.setContent(previewAsset.innerHTML);

    }

    return (
        <div id="previewAsset" style={{maxWidth: 600}}>
            
        </div>
    )

}

export default BRU_Inclusion_Block