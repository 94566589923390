/* TD Gardens */

const urlTDG = 'https://cloud.tdgarden-email.com/triggered-send-controls';
const urlBRU = 'https://cloud.bostonbruins-email.com/triggered-send-controls';

export const pauseTrigger = async (businessUnit) => {
    let url = businessUnit === 'TDG' ? urlTDG : urlBRU;
    console.log("pausing trigger...")
    const response = await fetch(url + '?a=pause');
    const result = await response.text();
    const trimmedResult = result.trim(); // trim white space
    const json = await JSON.parse(trimmedResult);
    console.log(json);
    return(json.Status);

}

export const updateTrigger = async (businessUnit, emailId,  subjectLine) => {
    let url = businessUnit === 'TDG' ? urlTDG : urlBRU;
    console.log("updating trigger...")
    const response = await fetch(url + '?a=update&id=' + emailId + '&sl=' + subjectLine);
    const result = await response.text();
    const trimmedResult = result.trim(); // trim white space
    const json = await JSON.parse(trimmedResult);
    console.log(json);
    return(json.Status);
}

export const publishTrigger = async (businessUnit) => {
    let url = businessUnit === 'TDG' ? urlTDG : urlBRU;
    console.log("publish trigger...")
    const response = await fetch(url + '?a=publish');
    const result = await response.text();
    const trimmedResult = result.trim(); // trim white space
    const json = await JSON.parse(trimmedResult);
    console.log(json);
    return(json.Status);
}

export const startTrigger = async (businessUnit) => {
    let url = businessUnit === 'TDG' ? urlTDG : urlBRU;
    console.log("start trigger...")
    const response = await fetch(url + '?a=start');
    const result = await response.text();
    const trimmedResult = result.trim(); // trim white space
    const json = await JSON.parse(trimmedResult);
    console.log(json);
    return(json.Status);
}

export const sendEmail = async (businessUnit) => {
    let url = businessUnit === 'TDG' ? urlTDG : urlBRU;
    console.log("sending email...")
    const response = await fetch(url + '?a=send');
    const result = await response.text();
    const trimmedResult = result.trim(); // trim white space
    const json = await JSON.parse(trimmedResult);
    console.log(json);
    return(json.Status);
}

