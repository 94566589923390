//import { ReactDOM } from 'react';
import { useState, useEffect, useRef } from 'react';
import process from "process";
import {
    Button,
    Card,
    DataTable,
    DataTableCell,
    DataTableColumn,
    ExpandableSection,
    Icon,
    InputIcon,
    Input,
    Spinner,
    Textarea
} from '@salesforce/design-system-react';
import FooterComponent from '../../components/Footer';
import startTestProcess from '../../helpers/testEmail';
//import sfmcAuth from '../../helpers/sfmcAuth';

var SDK = require('blocksdk');
var sdk = new SDK();

const SF_Import = () => {

    useEffect(() => {
        const url = document.URL;
        const referrer = document.referrer;
        const env = process.env.NODE_ENV;
        if (env === 'development') {
            return
        }
        if (env === 'production') {
            if (!referrer.includes(process.env.REFERRER)) {
                console.log("not authorized");
            } else {
                console.log("authorized");
            }
        }
    }, [])

    // useEffect(() => {
    //     //const url = "../../sfmc_api/sfmc_auth.php";
    //     const auth = async () => {
    //         const result = await fetch("http://localhost/sfmc-custom-block-sdk/SFMC_Auth.php", {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json"
    //             },
    //             body: JSON.stringify({ bu: "bru" })
    //         });
    //         const token = await result.text();
    //         const result2 = await fetch("http://localhost/sfmc-custom-block-sdk/SFMC_GET_Asset_by_ID.php", {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json"
    //             },
    //             body: JSON.stringify({ 
    //                 access_token: token, 
    //                 asset_id: "379269" 
    //             })
    //         });
    //         const asset = await result2.text();
    //         console.log(asset);
    //     }
    //     auth();
    // }, [])

    const blockTitle = 'Salesforce Import Block';
    const [spinner, setSpinner] = useState('hidden');
    const [connectionData, setConnectionData] = useState({
        Connected: false,
        Id: "",
        Email_ID__c: ""
    });
    const [recordData, setRecordData] = useState({

    });
    const slInputRef = useRef(null);
    const phInputRef = useRef(null);
    const [slInputDisabled, setSlInputDisabled] = useState(true);
    const [phInputDisabled, setPhInputDisabled] = useState(true);

    useEffect(() => {
        if (!slInputDisabled) {
            slInputRef.current.select();
            setSlInputDisabled(true);
        }
        if (!phInputDisabled) {
            phInputRef.current.select();
            setPhInputDisabled(true);
        }
    }, [slInputDisabled, phInputDisabled]);

    const CustomDataTableCell = ({ children, ...props }) => {
        const copyFields = ["Subject_Line__c", "Email_Preview_Text__c", "CTA_Link__c"];
        if (copyFields.includes(props.item.field)) {
            return (
                <DataTableCell {...props}>

                    <a href="#" onClick={(event) => {
                        event.preventDefault();
                        navigator.clipboard.writeText(props.item.value);
                    }}>
                        {/* {children} */}
                        <Icon
                            assistiveText={{ label: 'Copy' }}
                            category="utility"
                            name="copy"
                            size="x-small"
                            colorVariant="default"
                        />
                    </a>
                </DataTableCell>
            )
        } else {
            return (
                <DataTableCell {...props}>
                    {""}
                </DataTableCell>
            )
        }
    };
    CustomDataTableCell.displayName = DataTableCell.displayName;

    const columns = [
        <DataTableColumn
            key="field"
            label="Field"
            property="field"
            primaryColumn
            width="180px"
        />,
        <DataTableColumn
            key="value"
            label="Value"
            property="value"
            truncate
        />,
        <DataTableColumn
            key="copy"
            label="Copy"
            property="copy"
            width="75px"
        >
            <CustomDataTableCell />
        </DataTableColumn>
    ];

    const superContent = '<div style="width: 100%; padding: 5px 0 5px 0; background-color: #2990B2; color: #fff; font-size: 12px; font-weight: 500; letter-spacing: 2px; text-align: center;">' + blockTitle + '<br/><span style="font-size: 10px;">(this won\'t render)<span></div>';

    useEffect(() => { // On Load
        sdk.setBlockEditorWidth(600); // Set Block Width
        sdk.getData((data) => { // Get Data from Block
            console.log("get data:", data);
            if (Object.keys(data).length === 0) return
            // if data found
            // get data keys are not capitalized for some reason
            console.log(data);
            const captial = data =>
                Object.keys(data).reduce((acc, key) => {
                    acc[key.charAt(0).toUpperCase() + key.slice(1)] = data[key];
                    return acc;
                }, {});
            data = captial(data);
            console.log(data);
            // set data to state
            setConnectionData({
                ...connectionData,
                Id: data.id,
                Email_ID__c: data.email_ID__c,
                Connected: true
            });
            setRecordData({ ...data });
        });
        sdk.setSuperContent(superContent); // Set Super Content
    }, []);

    const sfmcAuth = async () => {
        // to do   
    }

    const onChangeValue = (e) => { // On Change Input
        console.log(e.target.id, e.target.value);
        setConnectionData({ ...connectionData, [e.target.id]: e.target.value });
    }

    const updateSFRecord = async () => { // Update Salesforce Record with Email Name
        //setSpinner('visible');
        const url = "https://mc13x7pm08rd2hw7jlz8ghg8szzm.pub.sfmc-content.com/lbid5ybe0bb";
        const params1 = "?erid=";
        const params2 = "&en=";
        const params3 = "&st=";
        const joined = url + params1 + connectionData.Id + params2 + connectionData.Email_ID__c + params3 + "Draft";
        //console.log(joined);
        const result = await fetch(joined);
        const response = await result.text();
        const trim = response.trim();
        console.log(trim);
        if (trim === "1") {
            getSFRecord();
        } else {
            alert("Error: Email Request not found in Salesforce. Please check the Email Request Id.");
            setSpinner('hidden');
        }
    }

    const getSFRecord = async () => { // Get Salesforce Record
        // post request
        // const options = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json', 
        //         'User-Agent': 'insomnia/8.6.1',
        //     },
        //     body: '{"Object":"Requests__c","Property":"Id","SimpleOperator":"equals","Value":"a0L8a00001E1YqYEAV","Fields":["Id","Name","Status__c","Subject_Line__c","Email_Preview_Text__c","Description__c", "CTA_Link__c", "Send_Date_Time__c", "Department__c", "Email_ID__c", "From_Address__c", "Segment__c", "SFMC_JobID__c"]}'
        // };
        // get request
        const url = 'https://mc13x7pm08rd2hw7jlz8ghg8szzm.pub.sfmc-content.com/5ehwy1lwwqq';
        const params = '?o=Requests__c&p=Id&s=equals&v=' + connectionData.Id + '&f=Id,Name,Status__c,Subject_Line__c,Email_Preview_Text__c,Description__c,CTA_Link__c,Send_Date_Time__c,Department__c,Email_ID__c,From_Address__c,Segment__c,SFMC_JobID__c';

        // const url = "https://mc13x7pm08rd2hw7jlz8ghg8szzm.pub.sfmc-content.com/mzgm0uynzht";
        // const params = "?erid=";
        //console.log(url + params);
        const result = await fetch(url + params)
        const response = await result.json();
        console.log(response);
        try {
            response[0].Connected = true;
            setConnectionData({ ...connectionData, Connected: true });
            setRecordData({ ...response[0] });
            sdk.setData({ ...response[0] }); // Save to Block
            sdk.setSuperContent(superContent); // Set Super Content
        } catch (error) {
            console.log(error);
        }
        setSpinner('hidden');
    }


    return (
        <div>

            <Card heading="Salesforce Data Connector">
                {/* Email Request Id */}
                <Input
                    label="Email Request Id"
                    id="Id"
                    placeholder="e.g. a0LUg000000YnO5MAK"
                    // iconRight="search"
                    onChange={onChangeValue}
                    value={connectionData.Id}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                    iconRight={
                        <InputIcon
                            assistiveText={{
                                icon: 'Clear',
                            }}
                            name="clear"
                            category="utility"
                            onClick={() => {
                                onChangeValue({ target: { id: "Id", value: "" } });
                            }}
                        />
                    }
                />
                {/* Email Name */}
                <Input
                    label="Email Name"
                    id="Email_ID__c"
                    placeholder="e.g. 240228_TGD_IN_x_OnSale"
                    onChange={onChangeValue}
                    value={connectionData.Email_ID__c}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                    iconRight={
                        <InputIcon
                            assistiveText={{
                                icon: 'Clear',
                            }}
                            name="clear"
                            category="utility"
                            onClick={() => {
                                onChangeValue({ target: { id: "Email_ID__c", value: "" } });
                            }}
                        />
                    }
                />
                {/* Connection Button */}
                <Button
                    label="Connect Salesforce"
                    iconCategory="utility"
                    iconName="link"
                    iconPosition="left"
                    disabled={connectionData?.Id?.length != 18 || connectionData?.Email_ID__c?.length === 0}
                    style={{ margin: 3 }}
                    onClick={updateSFRecord}
                />
                {/* Connected */}
                <div style={{ margin: 3, opacity: 1, display: 'flex', justifyContent: 'center' }}>
                    {/* {Object.keys(connectionData)?.map((key, index) => {
                        return (
                            <span key={index}>
                                <span style={{}}>{key}: </span>
                                <span style={{ fontWeight: '700', color: !connectionData[key] ? 'crimson' : 'mediumSeaGreen' }}>{connectionData[key]?.toString()} </span>
                            </span>
                        )
                    })} */}
                    <span style={{}}>Connected:&nbsp;</span>
                    <span style={{ fontWeight: '700', color: !connectionData?.Connected ? 'crimson' : 'mediumSeaGreen' }}>{connectionData?.Connected?.toString()} </span>
                </div>

                {/* Status */}
                <Input
                    label="Status"
                    id="Status__c"
                    placeholder=""
                    // onChange={onChangeValue}
                    value={recordData?.Status__c} //recordData?.Status__c
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                    disabled
                />
                {/* Subject Line */}
                <Input
                    inputRef={slInputRef}
                    label="Subject Line"
                    id="Subject_Line__c"
                    placeholder=""
                    // onChange={onChangeValue}
                    value={recordData?.Subject_Line__c} //recordData?.Subject_Line__c
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                    disabled={slInputDisabled}
                    iconRight={
                        <InputIcon
                            assistiveText={{
                                icon: 'Clear',
                            }}
                            name="copy"
                            category="utility"
                            size="x-small"
                            colorVariant="default"
                            onClick={(e) => {
                                setSlInputDisabled(false);
                            }}
                        />
                    }
                />
                {/* Preheader */}
                <Input
                    inputRef={phInputRef}
                    label="Preheader"
                    id="Email_Preview_Text__c"
                    placeholder=""
                    // onChange={onChangeValue}
                    value={recordData?.Email_Preview_Text__c} //recordData?.Email_Preview_Text__c
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                    disabled={phInputDisabled}
                    iconRight={
                        <InputIcon
                            assistiveText={{
                                icon: 'Clear',
                            }}
                            name="copy"
                            category="utility"
                            size="x-small"
                            colorVariant="default"
                            onClick={(e) => {
                                setPhInputDisabled(false);
                            }}
                        />
                    }
                />
                <Textarea id="description-text-area" label="Description" value={recordData?.Description__c?.replaceAll("~", '"').replaceAll(">", ">\n")} />

                <Button
                    label="Auth"
                    iconCategory="utility"
                    iconName="link"
                    iconPosition="left"
                    // disabled={connectionData?.Id?.length != 18 || connectionData?.Email_ID__c?.length === 0}
                    style={{ marginTop: 10 }}
                    onClick={sfmcAuth}
                />

            </Card>


            {/********** Email Request Data **********/}
            <Card heading="Email Request Data">
                {/********** Main Record Data **********/}
                <ExpandableSection
                    id="main-expandable-section"
                    title="Main Record Data"
                >
                    <div style={{ overflow: 'hidden' }}>
                        <DataTable items={Object.keys(recordData).map((item, index) => (
                            {
                                id: index?.toString(),
                                field: item,
                                value: recordData[item]?.toString(),
                                copy: "Copy to Clipboard",
                            }
                        )).sort((a, b) => a.field.localeCompare(b.field))}
                            id="DataTableExample-1-default"
                            striped
                            fixedLayout
                        >
                            {columns}
                        </DataTable>
                    </div>
                </ExpandableSection>

                {/********** Description **********/}
                {/* <ExpandableSection
                    id="description-expandable-section"
                    title="Description"
                >
                    <Textarea id="description-text-area" label="Description" value={recordData?.Description__c?.replaceAll("~", '"').replaceAll(">", ">\n")} />
                </ExpandableSection> */}

                {/********** Files **********/}
                <ExpandableSection
                    id="files-expandable-section"
                    title="Files"
                >
                    <p>nothing here yet</p>
                </ExpandableSection>

                {/********** Inclusions **********/}
                <ExpandableSection
                    id="inclusions-expandable-section"
                    title="Inclusions"
                >
                    <p>nothing here yet</p>
                </ExpandableSection>


            </Card>

            {/********** Loading Spinner **********/}
            <Spinner
                containerStyle={{ visibility: spinner }}
                size="small"
                variant="brand"
                assistiveText={{ label: 'Main Frame Loading...' }}
            />
            <FooterComponent />

        </div>
    )

}

export default SF_Import