//import { ReactDOM } from 'react';
import { useState, useEffect } from 'react';
import { Button, Card, Input, RadioGroup, Radio, Textarea, PageHeader, Spinner } from '@salesforce/design-system-react';
import FooterComponent from '../../components/Footer';

var SDK = require('blocksdk');
var sdk = new SDK();

const TDG_Banner = () => {

    const blockTitle = 'TDG Banner';
    const [spinner, setSpinner] = useState('hidden');
    const [stateData, setStateData] = useState({
        text: 'ON SALE',
        textColor: '#fff;',
        backgroundColor: '#4EBC41',
    });
    const valuesText = ['ANNOUNCEMENT', 'JUST ANNOUNCED', 'ON SALE', 'ON SALE NOW', 'PRESALE', 'PRESALE HAPPENING SOON', 'SUITES ON SALE'];
    const labelsText = ['ANNOUNCEMENT', 'JUST ANNOUNCED', 'ON SALE', 'ON SALE NOW', 'PRESALE', 'PRESALE HAPPENING SOON', 'SUITES ON SALE'];
    const valuesTextColors = ['#000', '#fff'];
    const labelsTextColors = ['Black', 'White'];
    const valuesBackgroundColors = ['#4EBC41', '#FEBD2F'];
    const labelsBackgroundColors = ['Green', 'Yellow'];

    //#region main functions
    const content = () => {
        return (`
            <table width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation">
                <tr>
                    <td width="100%" style="padding: 20px 0 0 0;">
                        <table width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation">
                            <tr>
                                <td width="100%" style="padding: 8px; background-color: ${stateData.backgroundColor}; color: ${stateData.textColor}; font-size:16px; letter-spacing:2px; letter-spacing: 1px; font-weight: 700; text-align: center;">
                                    ${stateData.text}
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        `)
    }
    // const superContent = () => {
    //     return ('<div style="width: 100%; padding: 5px 0 5px 0; background-color: #2990B2; color: #fff; font-size: 12px; font-weight: 500; letter-spacing: 2px; text-align: center;">' + blockTitle + '<br/><span style="font-size: 10px;">(this won\'t render)<span></div>');
    // }
    useEffect(() => { // On Load
        sdk.setBlockEditorWidth(600); // Set Block Width
        sdk.getView((view) => { // Set View
            console.log("get view:", view);
        });
        sdk.getCentralData((data) => {
            console.log("get central data:", data);
        });
        sdk.getData((data) => {
            console.log("get data:", data);
            if (Object.keys(data).length === 0) return
            setStateData({ ...data });
        });
    }, []);
    const onChangeValue = (e) => { // On Change Input
        console.log("on change value:", e.target.id, e.target.value);
        setStateData({ ...stateData, [e.target.id]: e.target.value });
    }
    useEffect(() => {
        sdk.setData({ ...stateData });
        sdk.setContent(content());
    },[stateData])
    // const setData = () => {
    //     console.log("set data:", stateData);
    //     sdk.setData({ ...stateData });
    //     sdk.setContent(content());
    //     // sdk.setSuperContent(superContent(), function(callback) {
    //     //     setSpinner('hidden');
    //     // });
    // }
    //#endregion

    return (
        <div>

            <div className="slds-page-header__title" style={{ margin: 10 }}>
                {blockTitle}
            </div>

            {/* Main Info */}
            <Card heading="Banner">
                {/********** Text **********/}
                <Input
                    label="Text"
                    id="text"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.text}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <RadioGroup
                    labels={labelsText}
                    onChange={(event) => onChangeValue({ target: { id: 'text', value: event.target.value } })}
                    disabled={false}
                    required={false}
                    name={false}
                    style={{ flexDirection: 'column' }}
                    styleContainer={{ flexDirection: 'column' }}
                //errorId={f}
                >
                    {valuesText.map((value, index) => (
                        <Radio
                            className="column"
                            key={value}
                            id={value}
                            labels={{ label: labelsText[index] }}
                            value={value}
                            checked={stateData.text === value}
                            variant="base"
                        />
                    ))}
                </RadioGroup>
                {/********** Text Color **********/}
                <Input
                    label="Text Color"
                    id="textColor"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.textColor}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <RadioGroup
                    labels={labelsTextColors}
                    onChange={(event) => onChangeValue({ target: { id: 'textColor', value: event.target.value } })}
                    disabled={false}
                    required={false}
                    name={false}
                    styl
                //errorId={f}
                >
                    {valuesTextColors.map((value, index) => (
                        <Radio
                            key={value}
                            id={value}
                            labels={{ label: labelsTextColors[index] }}
                            value={value}
                            checked={stateData.textColor === value}
                            variant="base"
                        />
                    ))}
                </RadioGroup>
                {/********** Background Color **********/}
                <Input
                    label="Background Color"
                    id="backgroundColor"
                    placeholder=""
                    onChange={onChangeValue}
                    value={stateData.backgroundColor}
                    styleInput={{ textAlign: 'left' }}
                    styleContainer={{}}
                />
                <RadioGroup
                    labels={labelsBackgroundColors}
                    onChange={(event) => onChangeValue({ target: { id: 'backgroundColor', value: event.target.value } })}
                    disabled={false}
                    required={false}
                    name={false}
                //errorId={f}
                >
                    {valuesBackgroundColors.map((value, index) => (
                        <Radio
                            key={value}
                            id={value}
                            labels={{ label: labelsBackgroundColors[index] }}
                            value={value}
                            checked={stateData.backgroundColor === value}
                            variant="base"
                        />
                    ))}
                </RadioGroup>

            </Card>

            {/* Save Button */}
            {/* <Button
                label="Save Content"
                iconCategory="utility"
                iconName="save"
                iconPosition="left"
                // disabled={stateData?.Id?.length != 18 || stateData?.Email_ID__c?.length === 0}
                style={{ marginTop: 10 }}
                onClick={setData}
            /> */}
             {/********** Loading Spinner **********/}
            <Spinner
                containerStyle={{ visibility: spinner }}
                size="small"
                variant="brand"
                assistiveText={{ label: 'Main Frame Loading...' }}
            />

            <FooterComponent />
        </div>
    )

}

export default TDG_Banner